import React from 'react'
import Link from 'gatsby-link'
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'
import GatsbyConfig from '../../gatsby-config'


const Header = ({ siteTitle }) => (
  <div className="nav-bar">
    <Helmet
      title={GatsbyConfig.siteMetadata.title}
      meta={[
        { name: 'description', content: 'Sahyadri Open-Source Community' },
        {
          name: 'keywords',
          content: 'Community, OpenSource, SOSC, sahyadri, student',
        },
      ]}
      link={[{ rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` }]}
      link={[{ rel: 'stylesheet', href: "https://fonts.googleapis.com/css?family=Kosugi+Maru" }]}
    />

    <div className="container">
      <div className="nav-items">
        
        <Link to="/" exact={true} activeClassName="active" className="kasen-none">
          <span className="brand">{siteTitle}</span>
        </Link>
        <ul>
          <li>
            <Link to="/" exact={true} activeClassName="active">
              ホーム
            </Link>
          </li>
          <li>
            <Link to="/events" exact={true} activeClassName="active">
              コース
            </Link>
          </li>
          <li>
            <Link to="/blog" exact={true} activeClassName="active">
              ブログ
            </Link>
          </li>
          <li>
            <Link to="/team" exact={true} activeClassName="active">
              会社
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
)



export default Header
