import React from 'react'
import Link from 'gatsby-link'


const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <p className="center">
        このサイトは、通常のHPより8倍はやい最新技術である、Gatsby.jsで作っております。サクサク動く当サイトをご体感ください。
      </p>
      <div className="info-area">
        <div className="column">
          <a href="https://github.com/oyj1217" target="_blank">GitHub</a>
          <a href="https://instagram.com/kuredojo/" target="_blank">Instagram</a>
          <a href="https://www.facebook.com/kureshima.dojo/?modal=admin_todo_tour" target="_blank">FaceBook</a>
        </div>
        <div className="column">
          <a href="https://goo.gl/forms/XVRdfeiEYTiHwx0A3" target="_blank">お問い合わせ</a>
          <Link to="/events" exact={true} activeClassName="active">
            コースはこちら
          </Link>
          <Link to="/blog" exact={true} activeClassName="active">
            ブログ
          </Link>

        </div>
        <div className="column">
          <p>
            <strong>運営：株式会社ソフトスタジオ</strong><br />
            教室：広島市中区大手町２丁目７−７ 小松ビル6F
            <br/><br/>
            info@softstudio.co.jp<br /><br />
            090-4427-2335
          </p>
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <p>呉島道場@ガチ系プログラマ育成塾 © { new Date().getFullYear() }</p>
    </div>
  </footer>
)

export default Footer
